export const ROUTER_LINKS = {
  accountListings: '/account/listings',
  accountProfile: '/account/profile',
  blog: '/blog',
  bookmarks: '/bookmarks',
  choosePackage: '/payments/choose-package',
  downloadApp: '/download-app',
  findingRoom: '/finding-a-room',
  help: '/support',
  home: '/',
  idCheck: '/id-check/custom-choose-id-type',
  listings: '/listings',
  login: '/login',
  logout: '/logout',
  megaphone: '/megaphone',
  messages: '/conversations/inbox',
  phoneAuth: '/phone-auth',
  press: '/press',
  privacy: '/privacy',
  profile: '/profile',
  rentingOutRoom: '/renting-out-a-room',
  reviews: '/reviews',
  settings: '/settings',
  sitemap: '/sitemaps',
  support: '/support',
  terms: '/terms',
  tutorial: '/tutorial',
  verification: '/verification',
} as const;

export const DOWNLOAD_APP_LINKS = {
  appStore: 'https://itunes.apple.com/us/app/roomster-roommate-finder-find/id1045548013',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.roomster',
} as const;

export const SOCIAL_MEDIA_LINKS = {
  facebook: 'https://www.facebook.com/RoomsterCorp',
  instagram: 'https://www.instagram.com/roomster/',
  pinterest: 'http://pinterest.com/roomster/',
  twitter: 'http://x.com/roomster',
  vimeo: 'https://vimeo.com/roomsterroommates',
  youtube: 'https://www.youtube.com/user/roomsterroommates',
} as const;

export const EXTERNAL_LINKS = {
  affiliateProgram: 'https://roomster.com/affiliate',
  fairHousing: 'http://portal.hud.gov/hudportal/HUD?src=/program_offices/fair_housing_equal_opp/FHLaws/yourrights',
  faq: 'https://support.roomster.com',
} as const;
